import { useForm } from 'vee-validate';
import yup from '../lib/yup-extended';
import { NewsletterPayload } from '../types/general';
import { useGeneralService, useConfigStore, useI18n, useAuth, useTracker, ref, useToast } from '#imports';

export const useNewsletter = () => {
  const { country, currency } = useConfigStore();
  const auth = useAuth();
  const { locale, t } = useI18n();
  const { jitsuEvent } = useTracker();
  const toast = useToast();

  const showDialog = ref<boolean>(false);
  const isBusy = ref<boolean>(false);

  const { handleSubmit, values } = useForm<{
    email: string;
  }>({
    validationSchema: yup.object({
      email: yup
        .string()
        .required(t('global.rqemail'))
        .matches(/^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, t('bookingform.recommendedemailerrormessage'))
        .matches(/^(?!.*\.\.)(?!.*\.$)[^\s@]+@[^\s@]+\.[^\s@.]+$/, t('bookingform.recommendedemailerrormessage')),
    }),
  });

  const onSubmit = handleSubmit(async () => {
    try {
      isBusy.value = true;

      jitsuEvent('user-click-button', {
        event_name: 'newsletter-subscribe-click',
        object_name: 'newsletter',
        object_parameter: 'subscribe',
      });

      let payload: NewsletterPayload = {
        email: values.email,
        country: country!,
        currency: currency!,
        lang: locale.value,
      };

      if (auth.loggedIn) {
        payload = {
          ...payload,
          firstName: auth.user?.firstName,
          lastName: auth.user?.lastName,
        };
      }

      const { result } = await useGeneralService().subscribeNewsletter(payload);

      if (result) showDialog.value = true;
    } catch {
      toast.show({ type: 'error', message: t('global.somethingwrong') });
    } finally {
      isBusy.value = false;
    }
  });

  return {
    values,
    isBusy,
    showDialog,
    onSubmit,
  };
};
